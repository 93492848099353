import axios from "axios";
import { getUrl } from "../../Utils";

import {
  RTR_SUCCESS,
  RTR_FAILURE,
} from "./Type";


export const addRtrData = userobj => {
    return (dispatch) => {
      console.log("userobj:::::",userobj);
      axios.post(`${getUrl()}/rtr`, (userobj))
  
        .then(response => {
          const JobApplyEntities = response.data
          console.log("response:::::",response.data);
          dispatch(rtrSuccess(JobApplyEntities));
        })
        .catch(error => {
          dispatch(rtrFailure(error.message));
        })
    }
  }


  export const rtrSuccess = (Obj) => {
    return {
      type: RTR_SUCCESS,
      payload: Obj,
    };
  };
  
  export const rtrFailure = (error) => {
    return {
      type: RTR_FAILURE,
      payload: error,
    };
  };
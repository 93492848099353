import React from 'react'
import { Form, Button, } from "react-bootstrap";
// import { FaFacebook } from "react-icons/fa";
// import { FiLinkedin, FiInstagram } from "react-icons/fi";
// import { ImGooglePlus } from "react-icons/im";
// import '../Footer/Footer.scss';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'


function Footer() {
    return (
        <React.Fragment>
          <div className="footer">
            <Container>
              <Row className="text-white">
                <Col xs={12} sm={5}>
                  <div className="font-weight-bold mb-2">Contact US</div>
                  <Form className="pb-2 pb-sm-0">
                    <Form.Group>
                      <Form.Control type="email" placeholder="Your email address" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control as="textarea" rows={3} placeholder="Your Message" />
                    </Form.Group>
                    <Form.Group>
                      <Button variant="primary">Submit</Button>
                    </Form.Group>
                  </Form>
                </Col>
                <Col xs={8} sm>
                  <div className="font-weight-bold mb-2">Frequantly asked question</div>
                  <Row>
                    <Col>
                      <Nav className="flex-column">
                        <Nav.Link href="/hbits/aboutus" className="px-0 text-white">About Us</Nav.Link>
                        {/* <Nav.Link className="px-0 text-white">Our Blogs</Nav.Link> */}
                        {/* <Nav.Link className="px-0 text-white">Chat with us</Nav.Link> */}
                        <Nav.Link href="/hbits/contactus" className="px-0 text-white">Contact Us</Nav.Link>
                        {/* <Nav.Link className="px-0 text-white">Our Privacy</Nav.Link> */}
                      </Nav>
                    </Col>
                    <Col>
                      <Nav className="flex-column">
                        {/* <Nav.Link className="px-0 text-white">Who are we?</Nav.Link>
                        <Nav.Link className="px-0 text-white">Why HBITS?</Nav.Link>
                        <Nav.Link className="px-0 text-white">Support</Nav.Link>
                        <Nav.Link className="px-0 text-white">Refferal</Nav.Link>
                        <Nav.Link className="px-0 text-white">Security</Nav.Link> */}
                      </Nav>
                    </Col>
                  </Row>
                </Col>
                <Col xs={4} sm="auto">
                  {/* <div className="font-weight-bold mb-2">Find Jobs</div> */}
                  <Nav className="flex-column">
                    {/* <Nav.Link className="px-0 text-white">India Jobs</Nav.Link>
                    <Nav.Link className="px-0 text-white">US Jobs</Nav.Link>
                    <Nav.Link className="px-0 text-white">UK Jobs</Nav.Link>
                    <Nav.Link className="px-0 text-white">Canada Jobs</Nav.Link>
                    <Nav.Link className="px-0 text-white">Other Jobs</Nav.Link> */}
                  </Nav>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="copyright">
            © Copyright2021By GreyCell Designs All Rights Reserved.
          </div>
        </React.Fragment>
    )
}


export default Footer

import React, { Component } from 'react'
import { Container, Card, Col, Button, Form, Row } from "react-bootstrap";
import Header from '../../component/Header/Header'
import { connect } from "react-redux";
import { addRtrData } from '../../redux/RightToRepresent/Action';
import { change, reduxForm, getFormValues, getFormSyncErrors, getFormSubmitErrors, hasSubmitSucceeded } from 'redux-form';
import { withRouter } from 'react-router-dom';
import moment from "moment";
import ReactTOPdf from "react-to-pdf";
// import Image from 'react-bootstrap/Image'
import {
    get as _get,
    isEmpty as _isEmpty,
} from 'lodash';
const formName = "RTRForm";

const validate = (values) => {

    const errors = {};
    if (!values.name) {
        errors.name = 'Attribute Name Required';
    }
    return errors;
}

class RightToRepresent extends Component {
    constructor(props) {
        super(props);
        // this.props.clearRecord();
        this.state = {
            name: "",
            signature: "",
            date: "",
            address: "",
            employment_Status: "",
            btnDisable: false,
            file: null,
            fileErr: "",
            error: "",
            employment_Status_other: "",
        }
        this.todate = new Date().toLocaleString("en-US", { month: "long" }) + "," + moment().format("DD,YYYY")
        this.ref = React.createRef();
        this.handleChange = this.handleChange.bind(this)
        this.status = false;
    }

    handleChange(event) {
        const file_size = event.target.files[0].size
        const file_type = event.target.files[0].type
        const file_name = event.target.files[0].name
        console.log("file_size::::::", file_size, file_type);
        if (file_size <= 20000 && (file_type === "image/jpeg" || file_type === "image/jpg" || file_type === "image/png")) {
            this.setState({
                file: URL.createObjectURL(event.target.files[0]),
                signature: file_name,
                fileErr: ""
            })
        }
        else {
            this.setState({ signature: "", fileErr: "Please Select small file." })
        }
    }

    save = () => {
        if (!_isEmpty(this.state.employment_Status)) {
            this.setState({ btnDisable: true, error: "" })
        } else {
            this.setState({ error: "please Select eEmployment Status. " })
        }
        const RTRObj = {
            HBITS_ID: _get(this.props, "match.params.id", ""),
            name: this.state.name,
            signature: this.state.signature,
            date: this.state.date,
            status: this.state.employment_Status,
            other_status: this.state.employment_Status_other,
            address: this.state.address,
        }
        this.props.addRtrData(RTRObj);
        this.reset();
    }

    reset = () => {
        this.setState({
            name: "",
            signature: "",
            date: "",
            address: "",
            employment_Status: "",
            employment_Status_other: "",
            btnDisable: false,
            file: null,
            fileErr: "",
            error: "",
        })
    }

    employmentStatus = () => {
        if (!_isEmpty(this.state.employment_Status)) {
            if (this.state.employment_Status === "Other") {
                if (_isEmpty(this.state.employment_Status_other)) {
                    this.setState({ error: "Employment Status can not be Empty." });
                    return false;
                } else return true;
            }
            else return true;
        }
        else return false;

    }

    handlePrint = () => {
        const w = window.open();
          w?.document.write(this.ref.current.innerHTML);
          w?.print();
    }

    render() {
        const { handleSubmit } = this.props;
        return (
            <>
                <Header data="Right to Represent" />
                <Container ref={this.ref}>
                   
                        <Card style={{ width: '50rem' }} className="my-3 shadow" >
                            <Card.Body>
                                <center>
                                    <img src="../../images/pdf_logo.png" alt='' fluid width={130} />
                                    <center><Card.Text>----------------------------------------------------------------------------------------------------------------------</Card.Text></center>
                                    <Card.Title>Right to Represent</Card.Title>
                                </center>
                                <Col xs={6} sm="auto" className="text-sm-left">

                                    <form onSubmit={handleSubmit(this.save)}>
                                        <Card.Text>
                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={2}>Date:</Form.Label>
                                                <Form.Label column sm={14}>{this.todate} </Form.Label>
                                            </Form.Group></Card.Text>
                                        <Card.Text><Form.Group as={Row} className="mb-3">
                                            <Form.Label column sm={2}>TO:</Form.Label>
                                            <Col sm={6}><Form.Control type="text" required name="name" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} /></Col>
                                        </Form.Group></Card.Text>
                                        <Card.Text><Form.Group as={Row} className="mb-3">
                                            <Form.Label column sm={2}>FROM:</Form.Label>
                                            <Form.Label column sm={14}>David, Contracts & Client Relationship Manager</Form.Label>
                                        </Form.Group></Card.Text>
                                        <Card.Text><Form.Group as={Row} className="mb-3">
                                            <Form.Label column sm={2}>CC:</Form.Label>
                                            <Form.Label column sm={14}>Ruby G, Greycell Labs</Form.Label>
                                        </Form.Group></Card.Text>
                                        <center>
                                            <Card.Title>SUBJECT: Confirmation of Exclusive Permission to Submit Your Profile </Card.Title>
                                        </center>
                                        <Card.Text>Greycell Labs Inc. will be submitting your profile to the NY Information Technology Service requisition {_get(this.props, "match.params.id", "")}.</Card.Text>
                                        <Card.Text>Please sign, date and return this form, confirming that you are providing Greycell Labs Inc with exclusive permission to submit your profile. Candidates who do not return this form will not be submitted.</Card.Text>
                                        <Card.Text>Please contact me if you have any questions at ruby@greylabs.com or 732-444-8990</Card.Text>
                                        <Card.Text>Greycell Labs Inc has exclusive permission to submit my profile for the position identified above.</Card.Text>
                                        <Card.Text>
                                            {this.state.fileErr.length > 0 && <div style={{ color: "red" }}>{this.state.fileErr}</div>}
                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={2}>Signature:</Form.Label>
                                                {/* {_isEmpty(this.state.signature) ?
                                                    <Col sm={6}><Form.Control type="file" required onChange={this.handleChange} /></Col> :
                                                    <Col sm={6}><img src={this.state.file} alt='' width={100} hight={100} /></Col>} */}
                                                <Col sm={6}><Form.Control type="text" required name="signature" value={this.state.signature} onChange={(e) => this.setState({ signature: e.target.value })} /></Col>
                                            </Form.Group>

                                        </Card.Text>
                                        <Card.Text>
                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={2}>Date:</Form.Label>
                                                <Col sm={6}><Form.Control type="date" required name='date' value={this.state.date} onChange={(e) => this.setState({ date: e.target.value })} /></Col>
                                            </Form.Group></Card.Text>
                                        <Card.Text>
                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={2}>Address:</Form.Label>
                                                <Col sm={6}> <Form.Control as="textarea" rows={1} required name="address" value={this.state.address} onChange={(e) => this.setState({ address: e.target.value })} /></Col>
                                            </Form.Group></Card.Text>
                                        <Card.Text>
                                            {this.state.error.length > 0 && <div style={{ color: "red" }}>{this.state.error}</div>}
                                            <fieldset>
                                                <Form.Group as={Row} className="mb-3">
                                                    <Form.Label as="legend" column sm={5}>Employment Status (please check one):</Form.Label><br></br>
                                                    <Col sm={6} >
                                                        <Form.Check name="employment_Status" type="radio" label="Citizen" value="Citizen" onChange={(e) => this.setState({ employment_Status: e.target.value })} />
                                                        <Form.Check name="employment_Status" type="radio" label="Green Card" value="Green Card" onChange={(e) => this.setState({ employment_Status: e.target.value })} />
                                                        <Form.Check name="employment_Status" type="radio" label="EAD" value="EAD" onChange={(e) => this.setState({ employment_Status: e.target.value })} />
                                                        <Form.Check name="employment_Status" type="radio" label="H1B" value="H1B" onChange={(e) => this.setState({ employment_Status: e.target.value })} />
                                                        <Form.Check name="employment_Status" type="radio" label="Other" value="Other" onChange={(e) => this.setState({ employment_Status: e.target.value })} />
                                                        {this.state.employment_Status === "Other" && <>{'  '} <Form.Control name="employment_Status_other" type="text" value={this.state.employment_Status_other} onChange={(e) => this.setState({ employment_Status_other: e.target.value })} /></>}
                                                    </Col></Form.Group></fieldset></Card.Text>
                                        { (!_isEmpty(this.state.name) && !_isEmpty(this.state.date) && !_isEmpty(this.state.address) && !_isEmpty(this.state.signature) && !_isEmpty(this.state.employment_Status)) ?
                                                (this.state.employment_Status === "Other" && _isEmpty(this.state.employment_Status_other)) ? "" :
                                                    <ReactTOPdf targetRef={this.ref} >
                                                        {({ toPdf }) => 
                                                            <center><Button variant="primary" type="submit" disabled={this.state.btnDisable} onClick={toPdf} >
                                                                Submit
                                                            </Button>{' '}
                                                            <Button variant="primary" disabled={this.state.btnDisable} onClick={this.handlePrint} >
                                                                Print
                                                            </Button></center>
                                                        }
                                                    </ReactTOPdf> : ""}
                                    </form>
                                    <center><Card.Text>-----------------------------------------------------------------------------------------------------------------</Card.Text></center>
                                    <center>
                                        <Card.Text>GREYCELL LABS INC<br></br>
                                            190 Lincoln Highway, Suite 201<br></br>
                                            Edison NJ 08820</Card.Text></center>
                                </Col>
                            </Card.Body>
                        </Card>
                </Container>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let formData = _get(state, "rightToRepresent.record.data", {})


    const newState = {
        initialValues: formData,
        formValues: getFormValues(formName)(state),
        formErrors: getFormSyncErrors(formName)(state),
        submitErrors: getFormSubmitErrors(formName)(state),
        submitSucceeded: hasSubmitSucceeded(formName)(state),
    }

    return newState;
}

const mapDispatchToProps = dispatch => {
    console.log(formName, "MapDispatchToProps", dispatch)
    return {
        addRtrData: (RTRObj) => dispatch(addRtrData(RTRObj)),
        setFieldValue: (field, value) => dispatch(change(formName, field, value)),
    }
}
export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(
        reduxForm({
            form: formName, // a unique identifier for this form
            enableReinitialize: true,
            validate, // validation for form
        })(RightToRepresent),
    ),
);



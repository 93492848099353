import React, { useState } from 'react'
// import '../Header/Header.scss'
import { Navbar, Nav } from "react-bootstrap"
import Container from 'react-bootstrap/Container'
// import Row from 'react-bootstrap/Row'
// import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
// import { useDispatch } from 'react-redux'
// import { useHistory, useLocation } from 'react-router-dom';
// import Figure from 'react-bootstrap/Figure'
// import Card from 'react-bootstrap/Card'
// import { LogOut } from '../../redux/Auth/AuthAction';
// import Card from 'react-bootstrap/Card'
import Modal from "react-bootstrap/Modal";
import Singup from '../SignUp/Singup'
import RecruiterSignup from "../SignUp/Recruiter_Signup";
import Login from '../Login/Login'
// import Contactus from '../../container/Contact Us/Contactus'

export default function Header(props) {
	// const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
	// // console.log(user);
	// const dispatch = useDispatch()
	// const history = useHistory()
	// const location = useLocation()

	const [show, setShow] = useState(false);
	const [showAdd, setShowAdd] = useState(false);
	const [showlogin, setShowlogin] = useState(false);
	const handleClose = () => setShow(false);
	// const handleShow = () => setShow(true);
	const handleCloseAdd = () => setShowAdd(false);
	// const handleShowAdd = () => setShowAdd(true);
	const handleCloselogin = () => setShowlogin(false);
	// const handleShowlogin = () => { setShowlogin(true) };

	// const logout = () => {
	// 	const logoutdata = null;
	// 	dispatch(LogOut({ logoutdata }));
	// 	history.push('/hbits')
	// 	// setUser(" ")
	// }

	// useEffect(() => {
	// 	// const token = user?.token;
	// 	// setUser(JSON.parse(localStorage.getItem('profile')));
	// }, [location]);

	return (

		<React.Fragment>
			<Container>
				<Navbar expand="md" className="px-0" >
					<Navbar.Brand href="/hbits">
						<Image src="../images/logo.svg" fluid width={70} />
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
						<Nav className="mr-auto">
							{/* <NavDropdown title="For Candidates" id="candidate-nav-dropdown">
								{user?.result && user?.result.role === "User" ?
									<NavDropdown.Item href="/hbits/applyjoblist">Apply Job List</NavDropdown.Item> 
									:
									<NavDropdown.Item onClick={() => handleShowlogin()}>Apply Job List</NavDropdown.Item>
								}
							</NavDropdown> */}
							{/* {user?.result && user?.result.role === "Recruiter" &&
								<NavDropdown title="For Employers" id="employers-nav-dropdown" >
									<NavDropdown.Item href="/hbits/Jobseeker">Candidates List</NavDropdown.Item>
								</NavDropdown>
							} */}
							<Nav.Link href="/hbits/aboutus" >About Us</Nav.Link>
							<Nav.Link href="/hbits/contactus">Contact Us</Nav.Link>
							{/* <Nav.Link href="/hbits/rtr">Right to Represent</Nav.Link> */}

						</Nav>
						{/* <div className="py-2 py-sm-0">
							{user?.result ?
								<>
									{user?.result.firstname}&nbsp;{user?.result.lastname}&nbsp;
										<Button id="btnDrop1" type="button" onClick={logout} className=" btn-primary marg " >Logout</Button>
								</>
								:
								<>
									<DropdownButton as={ButtonGroup} title="Sign up" id="basic-nav-dropdown" variant="primary">
										<Dropdown.Item onClick={() => handleShow()}>Job Seeker</Dropdown.Item>
										<Dropdown.Item onClick={() => handleShowAdd()}>Recruiter</Dropdown.Item>
									</DropdownButton>{' '}
									<Button variant="primary" onClick={() => handleShowlogin()}>Login</Button>
								</>
							}
						</div> */}
					</Navbar.Collapse>
				</Navbar>
					{/* <Col xs={6} sm={1} className="text-center text-sm-left">
						<Navbar.Brand href="/hbits"><img width={70} src="../images/logo.svg" className="image_style" alt="" /></Navbar.Brand>
					</Col> */}
						{/* <Navbar>
							<Navbar.Toggle aria-controls="basic-navbar-nav" />
							<Navbar.Collapse id="basic-navbar-nav">
							<Nav variant="pills" className="mx-auto">
							<NavDropdown title="FOR CANDIDATES" id="basic-nav-dropdown">
							{user?.result && user?.result.role === "User" ?
							<NavDropdown.Item href="/hbits/applyjoblist">Apply Job List</NavDropdown.Item> :
							<NavDropdown.Item onClick={() => handleShowlogin()}>Apply Job List</NavDropdown.Item>
						}
						</NavDropdown>
						
						{user?.result && user?.result.role === "Recruiter" &&
						<NavDropdown title="FOR EMPLOYERS" id="basic-nav-dropdown" >
						
						<NavDropdown.Item href="/hbits/Jobseeker">Candidates List</NavDropdown.Item>
						</NavDropdown>
					}
					<Nav.Link href="/hbits/aboutus" >ABOUT US</Nav.Link>&nbsp;
					<Nav.Link>CONTACT US</Nav.Link>
					</Nav>
					</Navbar.Collapse>
				</Navbar> */}
			</Container>
			<div className="banner">
				<div className="banner-slogan">{props.data}</div>
			</div>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Job Seeker Sign-Up</Modal.Title>
				</Modal.Header>
				<Singup />
			</Modal>
			<Modal show={showlogin} onHide={handleCloselogin}>
				<Modal.Header closeButton>
					<Modal.Title>Login</Modal.Title>
				</Modal.Header>
				<Login />
			</Modal>
			<Modal show={showAdd} onHide={handleCloseAdd}>
				<Modal.Header closeButton>
					<Modal.Title>Recruiter Sign-Up</Modal.Title>
				</Modal.Header>
				<RecruiterSignup />
			</Modal>
		</React.Fragment>

	)
}

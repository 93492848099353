import React, { Fragment } from 'react'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Header from '../../component/Header/Header'

export default function Aboutus() {
  return (
    <Fragment>
      <Header data="About Us" />
      <Container>
        <Card className="my-3 shadow">
          <Card.Body>
            <h3>About Greycell</h3>
            <p>We build more than IT systems. We build trust.</p>
            <div className="display-4">Your Success Drives Our Business.</div>
            <p>
              Since 2004, Greycell Labs Inc - a Inc. 500/5000 company, is devoted to helping major organizations plan and implement technology initiatives. Greycell Labs Inc has been ranked as one of the fastest growing INC 5000 firms in America for the year 2016. Greycell is a leading IT firm that provides a variety of technology-based services and solutions including IT consulting, business and technology services, custom software design, development, testing, deployment, and Integration support to to fulfill the needs of local, regional and global corporations of any size.
            </p>
            <Row>
              <Col xs={12} sm>
                <h5>Our Mission</h5>
                <ul>
                  <li>To provide superior IT products and services that:
                    <ul>
                      <li>CUSTOMERS recognize and rely upon,</li>
                      <li>EMPLOYEES are proud of, </li>
                      <li>PARTNERS winning network of customers and suppliers creating mutual, enduring value. </li>
                    </ul>
                  </li>
                </ul>
              </Col>
              <Col xs={12} sm>
                <h5>
                  Our Values
                </h5>
                <ul>
                  <li>Productivity: Be effective, lean and fast-moving organization.</li>
                  <li>Partners: Nurture a winning network of customers and suppliers.</li>
                  <li>People: Be a great work place where employees are inspired to deliver their best</li>
                </ul>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xs={12} sm className="text-center" >
                <div className="skillred">
                  <div className="number">100</div>
                  <div className="title">
                    Customer centric
                  </div>
                </div>
              </Col>
              <Col xs={12} sm className="text-center" >
                <hr className="d-block d-sm-none" />
                <div className="skillgreen">
                  <div className="number">100</div>
                  <div className="title">
                    Leader in the consulting business
                  </div>
                </div>
              </Col>
              <Col xs={12} sm className="text-center" >
                <hr className="d-block d-sm-none" />
                <div className="skillblue">
                  <div className="number">100</div>
                  <div className="title">
                    High Quality at High Speed
                  </div>
                </div>
              </Col>
              <Col xs={12} sm className="text-center" >
                <hr className="d-block d-sm-none" />
                <div className="skillorange">
                  <div className="number">100</div>
                  <div className="title">
                    Trusted Career Resource
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </Fragment>
  )
}

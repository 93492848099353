import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Button, Form, InputGroup } from "react-bootstrap";
// import './singup.scss'
import { ToastContainer, toast } from 'react-toastify';
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import 'react-toastify/dist/ReactToastify.css';
import { connect, useDispatch } from 'react-redux';
import { recruitersignup } from '../../redux/Auth/AuthAction';
import { useHistory } from 'react-router-dom';
import { Cities } from "../City-List/cities";
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'

function Recruiter_Singup(props) {

    const dispatch = useDispatch()
    const history = useHistory()
    const { ErrorData } = props;
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [email2, setEmail2] = useState("");
    const [phoneno, setPhoneno] = useState("");
    const [city, setCity] = useState("Select");
    const [state, setState] = useState("New Jersey");
    const [country, setCountry] = useState("USA");
    const [password, setPassword] = useState("");
    const [conpassword, setConpassword] = useState("");

    const [firstnameErr, setFirstnameErr] = useState({});
    const [lastnameErr, setLastnameErr] = useState({});
    // const [emailErr, setEmailErr] = useState({});
    // const [emailErr1, setEmailErr1] = useState({});
    // const [cityErr, setCityErr] = useState({});
    // const [phonenoErr, setPhonenoErr] = useState({});
    const [passwordErr, setPasswordErr] = useState({});
    const [conpasswordErr, setConpasswordErr] = useState({});

    const [showPassword, setShowPassword] = useState(false);
    const handleShowPassword = () => setShowPassword((prevShowPAssword) => !prevShowPAssword);


    //*************************ReSet Form************************
    const reset = () => {
        setFirstname("");
        setLastname("");
        setEmail("");
        setEmail2("");
        setPhoneno("");
        setCity("");
        setPassword("");
        setConpassword("");
        setFirstnameErr("");
        setLastnameErr("");
        // setEmailErr("");
        // setEmailErr1("");
        // setPhonenoErr("");
        setPasswordErr("");
        setConpasswordErr("");
        setError("");
    }

    //******************************Check Form Validation*****************************
    const formValidation = () => {
        const firstnameErr = {};
        const lastnameErr = {};
        // const emailErr = {};
        // const emailErr1 = {};
        // const phonenoErr = {};
        // const cityErr = {};
        const passwordErr = {};
        const conpasswordErr = {};
        let isValid = true;

        if (!firstname.match(/[A-Za-z]+$/)) {
            firstnameErr.firstNameShort = "Please Enter Only Alphabets.";
            isValid = false;
        }

        if (!lastname.match(/[A-Za-z]+$/)) {
            lastnameErr.lastNameShort = "Please Enter Only Alphabets.";
            isValid = false;
        }

        // if (!email.match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/)) {
        //     emailErr.emailShort = "Please Enter Valid Email Id.";
        //     isValid = false;
        // }

        // if(email2 !== ""){
        // if (!email2.match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/)) {
        //     emailErr1.emailShort = "Please Enter Valid Email Id.";
        //     isValid = false;
        // }}

        // if (!phoneno.match(/^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$/)) {
        //     phonenoErr.pnShort = "Please Enter only 10 digits.";
        //     isValid = false;
        //     // 123 456 7890  ||  (123)456 7890  ||  (123) 456 7890  ||  (123)456-7890
        //     //(123) 456-7890 ||  123.456.7890   ||  123-456-7890
        // }

        // if (city === "Select") {
        //     cityErr.cityselect = "Please Select City";
        //     isValid = false;
        // }   

        if (password !== conpassword) {
            conpasswordErr.notmatch = "Please Make Sure Your Password Match.";
            isValid = false;
        }

        setFirstnameErr(firstnameErr);
        setLastnameErr(lastnameErr);
        // setEmailErr(emailErr);
        // setEmailErr1(emailErr1);
        setConpasswordErr(conpasswordErr);
        setPasswordErr(passwordErr);
        // setPhonenoErr(phonenoErr);
        // setCityErr(cityErr);

        return isValid;
    }

    const [error, setError] = useState()
    useEffect(() => {
        if (ErrorData.authData.message === "User already exists.") {
            setError("Email id is Already in use");
        }
        if (ErrorData.authData.message === "suc") {
            toast("Successfully Register");
            reset();
            window.location.reload();
            // await history.push("/login")
            // setSuccess(success+1);
        }
    }, [ErrorData.authData.message, history]);
    // if(success === 1){
    //     toast("Successfully Register");
    //     reset();
    // }

    //********************Submit Form Data****************************
    const formSubmit = async e => {
        e.preventDefault();
        const isValid = formValidation();
        if (isValid) {
            const formData = {
                firstname: firstname,
                lastname: lastname,
                email: email,
                email2: email2,
                password: password,
                conpassword: conpassword,
                phoneno: phoneno,
                city: city,
                state: state,
                country: country,
            }
            await dispatch(recruitersignup(formData))
        }
    }


    return (
        <div>
            <Container>
                <ToastContainer />
                <Row>
                    <Col md={12}>
                        <div>
                            <Form onSubmit={formSubmit}>
                                <ModalBody>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>First Name<span style={{ color: "red" }}>*</span></Form.Label>
                                                <Form.Control required type="text" name="firstname" title="Enter Only Alphabets"
                                                    value={firstname} onChange={(e) => setFirstname(e.target.value)} />
                                                {Object.keys(firstnameErr).map((key) => {
                                                    return <div style={{ color: "red" }}>{firstnameErr[key]}</div>
                                                })}
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Last Name<span style={{ color: "red" }}>*</span></Form.Label>
                                                <Form.Control required type="text" title="Enter Only Alphabets"
                                                    name="lastname" value={lastname} onChange={(e) => setLastname(e.target.value)} />
                                                {Object.keys(lastnameErr).map((key) => {
                                                    return <div style={{ color: "red" }}>{lastnameErr[key]}</div>
                                                })}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Email Id<span style={{ color: "red" }}>*</span></Form.Label>
                                                <Form.Control required type="email" title="Enter Your Email Id."
                                                    name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                                {/* {Object.keys(emailErr).map((key) => {
                                        return <div style={{ color: "red" }}>{emailErr[key]}</div>
                                        })} */}
                                                {error !== null && <div style={{ color: "red" }}>{error}</div>}
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Email Id</Form.Label>
                                                <Form.Control type="email" title="Enter Your Email Id."
                                                    name="email2" value={email2} onChange={(e) => setEmail2(e.target.value)} />
                                                {/* {Object.keys(emailErr1).map((key) => {
                                        return <div style={{ color: "red" }}>{emailErr1[key]}</div>
                                        })} */}
                                                {error !== null && <div style={{ color: "red" }}>{error}</div>}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Phone Number<span style={{ color: "red" }}>*</span></Form.Label>
                                                <Form.Control required type="text" title="Enter 123 456 7890"
                                                    name="phoneno" value={phoneno} onChange={(e) => setPhoneno(e.target.value)} />
                                                {/* {Object.keys(phonenoErr).map((key) => {
                                        return <div style={{ color: "red" }}>{phonenoErr[key]}</div>
                                        })} */}
                                            </Form.Group>
                                        </Col>

                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Select City<span style={{ color: "red" }}>*</span></Form.Label>
                                                <Form.Control required as="select" value={city} onChange={(e) => setCity(e.target.value)} >
                                                    {Cities.cities && Cities.cities.map((e, key) => {
                                                        return <option key={key} value={e.Value}>{e.Value}</option>;
                                                    })}
                                                </Form.Control>
                                                {/* {Object.keys(cityErr).map((key) => {
                                        return <div style={{ color: "red" }}>{cityErr[key]}</div>
                                        })} */}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row md={12}>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>State<span style={{ color: "red" }}>*</span></Form.Label>
                                                <Form.Control disabled type="text" title="Please Enetr Only Digits."
                                                    name="state" value={state} onChange={(e) => setState(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Country<span style={{ color: "red" }}>*</span></Form.Label>
                                                <Form.Control disabled type="text" title="Please Enetr Only Digits."
                                                    name="country" value={country} onChange={(e) => setCountry(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row md={12}>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Password<span style={{ color: "red" }}>*</span></Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Control required type={showPassword ? "text" : "password"} title="Enter Password."
                                                        name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                                    <InputGroup.Append>
                                                        <InputGroup.Text id="basic-addon2">{showPassword ? <AiFillEyeInvisible onClick={handleShowPassword} /> : <AiFillEye onClick={handleShowPassword} />}</InputGroup.Text>
                                                    </InputGroup.Append>
                                                </InputGroup>

                                                {Object.keys(passwordErr).map((key) => {
                                                    return <div style={{ color: "red" }}>{passwordErr[key]}</div>
                                                })}
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Confirm Password<span style={{ color: "red" }}>*</span></Form.Label>
                                                <Form.Control required type="password" title="Enter Password."
                                                    name="conpassword" value={conpassword} onChange={(e) => setConpassword(e.target.value)} />
                                                {Object.keys(conpasswordErr).map((key) => {
                                                    return <div style={{ color: "red" }}>{conpasswordErr[key]}</div>
                                                })}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </ModalBody>
                                <ModalFooter>
                                    <Button variant="primary" type="submit">Submit</Button>
                                    <Button variant="primary" onClick={reset}>Reset</Button>
                                </ModalFooter>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        ErrorData: state.auth,
    }
}

export default connect(mapStateToProps)(React.memo(Recruiter_Singup));
import React, { useEffect, useState } from 'react';
import { fetchEmailList } from "../../redux/JobList/jobListAction";
import { get as _get } from "lodash";
import { Button, Modal } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { FaSearch } from "react-icons/fa";
import { FiClock } from "react-icons/fi";
import { FcBookmark } from "react-icons/fc";
import { IoBriefcase } from "react-icons/io5";
import { HiLocationMarker } from "react-icons/hi";
import { IoLocationSharp } from "react-icons/io5";
import Header from '../../component/Header/Header';
// import { MdWork } from "react-icons/md";
// import ApplyJobForm from '../ApplyJobForm/ApplyJobForm';
import ApplyJobForm from '../../component/ApplyJobForm/ApplyJobForm';
import Paginations from "../../component/paginations/Paginations";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min';
// import "../../component/JobList/jobList.scss";
// import logo from '../../images/logo.svg';
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import InputGroup from 'react-bootstrap/InputGroup'
// import Image from 'react-bootstrap/Image'
// import FigureImage from 'react-bootstrap/FigureImage'

import Legend from '../../component/legend/legend'

const moment = require("moment");
moment.suppressDeprecationWarnings = true;

export const JobList = (props) => {
  // const [userlogin, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const dispatch = useDispatch();
  const { EmailReceiverListData } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [id, setid] = useState("");
  const [search, setSearch] = useState("");
  const [searchcity, setSearchCity] = useState("");
  const [showPerPage] = useState(15);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  useEffect(() => {
    dispatch(fetchEmailList());
    // setUser(JSON.parse(localStorage.getItem('profile')));
  }, [dispatch]);

  //***************15 Days Count Function****************  
  var date2 = moment().format('L');
  var date1 = '';
  const daycount = (sentdate) => {
    date1 = moment((sentdate)).format("MM/DD/YYYY");
    return (new Date(date2) - new Date(date1)) / (1000 * 60 * 60 * 24);
  }

  
  //******************Set Pagination Start and End Point********************
  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end })
  };
  var Count = 0;

  return EmailReceiverListData.loading ? (
    <h2>Loading</h2>
  ) : EmailReceiverListData.error ? (
    <h2>{EmailReceiverListData.error}</h2>
  ) : (
    <>
      <Header data="Job List" />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Job Apply Form</Modal.Title>
        </Modal.Header>
        <ApplyJobForm id={id} />
      </Modal>
      <Container>
        <Card className="searchJob mb-3 shadow">
          <Card.Body>
            <Row>
              <Col>
                <Form.Label htmlFor="inlineFormInputGroup" srOnly>
                  Job Search
                    </Form.Label>
                <InputGroup>
                  <FormControl id="inlineFormInputGroup" placeholder="Job Search" onChange={(e) => setSearch(e.target.value)} />
                  <InputGroup.Append>
                    <InputGroup.Text><FaSearch /></InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
              <Col>
                <Form.Label htmlFor="inlineFormInputGroup" srOnly>
                  City, State or Zip Code
                    </Form.Label>
                <InputGroup>
                  <FormControl id="inlineFormInputGroup" placeholder="City, State or Zip Code" onChange={(e) => setSearchCity(e.target.value)} />
                  <InputGroup.Append>
                    <InputGroup.Text><IoLocationSharp /></InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
            </Row>
          </Card.Body>
        </Card>


        {EmailReceiverListData.jobListEntities.filter((val) => {
          return (_get(val, 'positiontitle', "").toLowerCase().includes(search.toLowerCase())
            && _get(val, 'physicalWorkAddress', "").toLowerCase().includes(searchcity.toLowerCase()) && (Count += 1));
        }).slice(pagination.start, pagination.end).map((user, i) => (
          <Card key={i} className="mb-3 shadow">
            <Card.Body>
              <Row>
                <Col xs={12} sm>
                  <div className="text-black-50 mb-2">
                    {daycount(_get(user, 'sentDate', "")) <= 15 ?
                      <>
                        {/* Active Job - Available for {15 - daycount(_get(user, 'requestDate', ""))} Days */}
                        Active Job
                      </>
                      :
                      <>Expired Job</>
                    }
                  </div>
                  <h6>{_get(user, "HBITS_ID", "")}</h6>

                  <h4>{_get(user, 'positiontitle', "")}</h4>
                  <div className="text-black-50">
                    <Legend title={_get(user, 'agencyName', "")} />
                    <Legend title={_get(user, 'neworExistingPosition', "")} >
                      <FcBookmark />
                    </Legend>
                    <Legend title={_get(user, 'positionSkill', "")} >
                      <IoBriefcase />
                    </Legend>
                    <Legend title={daycount(_get(user, 'sentDate', "")) === 0 ? "Today " : <>{daycount(_get(user, 'sentDate', ""))} Days ago </>} >
                      <FiClock />
                    </Legend>
                    <Legend title={_get(user, 'physicalWorkAddress', "")} >
                      <HiLocationMarker />
                    </Legend>
                  </div>
                </Col>
                <Col xs={12} sm="auto" className="text-sm-right">
                  <hr className="d-block d-sm-none" />
                  <Button variant="primary" href={`/hbits/${_get(user, "HBITS_ID", "")}`}>Job Details</Button> {' '}
                  <Button variant="primary" 
                  disabled={daycount(_get(user, 'sentDate', "")) <= 15 ? false : true}
                    onClick={(e) => { handleShow(); setid(user.HBITS_ID) }}>Apply Job</Button>{' '}
                    <Button variant="primary" href={`/hbits/rtr/${_get(user, "HBITS_ID", "")}`}
                     disabled={daycount(_get(user, 'sentDate', "")) <= 15 ? false : true}
                     > Right to Represent </Button>
                  {/* <Button variant="primary" disabled={daycount(_get(user, 'requestDate', "")) <= 15 && userlogin?.result &&
                    (userlogin?.result.role === "User" || userlogin?.result.role === "Recruiter") ? false : true}
                    onClick={(e) => { handleShow(); setid(user.HBITS_ID) }}>Apply Job</Button> */}
                  {/* {daycount(user.requestDate) <= 15 && userlogin?.result && userlogin?.result.role === "User" ?
                      <Button name="isOpen" onClick={(e) => { handleShow(); setid(user.HBITS_ID) }} variant="primary" >Apply Job</Button>
                      :<Button name="isOpen" disabled variant="primary" >Apply Job</Button>
                    } */}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ))}

        <Paginations showPerPage={showPerPage}
          onPaginationChange={onPaginationChange}
          // total={EmailReceiverListData.jobListEntities.length}
          total={Count}
        />
      </Container>
      {/* <div className="container">
            <div className="card">
              <div className="card-body qw">
                <div className="row">
                  <div className="col-md-6 col-sm-12 d-flex align-items-center `justify-content-center">
                    <div className="input-group mb-3 position-relative">
                      <input type="text" className="form-control custom-input input-border-color-custom"
                        placeholder="Job Search " aria-label="Recipient's username"
                        aria-describedby="basic-addon2"  />
                      <span className="input-group-text input-border-color-custom" id="basic-addon2"></span>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 d-flex align-items-center `justify-content-center">
                    <div className="input-group mb-3 position-relative">
                      <input type="text" className="form-control custom-input input-border-color-custom"
                        placeholder="City, State or Zip Code" aria-label="Recipient's username"
                        aria-describedby="basic-addon2" onChange={(e) => setSearchCity(e.target.value)} />
                      <span className="input-group-text input-border-color-custom" id="basic-addon2"><IoLocationSharp /></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    EmailReceiverListData: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEmailList: () => dispatch(fetchEmailList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(JobList));

import React, { useState } from 'react'
// import "../paginations/Paginations.scss";

const Paginations = ({ showPerPage, onPaginationChange, total }) => {
    const [counter, setCounter] = useState(1);
    var numberofButtons = Math.ceil(total / showPerPage);

    const onbuttonClick = (type) => {
        if (type === "prev") {
            if (counter === 1) {
                onClickActiveButton(1)
            }
            else {
                onClickActiveButton(counter - 1)
            }
        } else if (type === "next") {
            if (numberofButtons === counter) {
                onClickActiveButton(counter)
            } else {
                onClickActiveButton(counter + 1)
            }
        }
    }
    const onClickActiveButton = (index) => {
        setCounter(index);
        const value = showPerPage * index;
        onPaginationChange(value - showPerPage, value);
    }
    return (
        <div className="number">
            <nav aria-label="Page navigation example" >
                <ul className="pagination">
                    <p className="page-item"><button className="page-link" onClick={() => onbuttonClick('prev')}>Previous</button></p>
                    {
                        new Array(numberofButtons).fill("").map((el, index) => (
                            <p key={index} className={`page-item ${index + 1 === counter ? "active" : null}`}>
                                <button className="page-link" onClick={() => onClickActiveButton(index + 1)}>{index + 1}</button></p>
                        ))
                    }
                    <p className="page-item"> <button className="page-link" onClick={() => onbuttonClick('next')}>Next</button></p>
                </ul>
            </nav>
        </div>
    )
}
export default Paginations;

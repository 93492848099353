import "./app.scss";
import React from 'react'
import { Switch, Route, Redirect } from "react-router-dom";
import JobList from "./container/joblist/jobList";
import JobDetails from "./container/jobDetails/jobDeatils";
import ApplyJobForm from "./component/ApplyJobForm/ApplyJobForm";
import { Provider } from "react-redux";
import store from './redux/store';
// import Header from "../src/component/Header/Header";
import Footer from "../src/component/Footer/Footer";
import Login from "./component/Login/Login";
import Signup from "./component/SignUp/Singup"
import Recruiter_Signup from "./component/SignUp/Recruiter_Signup";
import Job_seeker_list from "./container/Recruiter/Job_seeker_list";
import Applyjoblist from "./container/JobSeeker/Applyjoblist";
import Aboutus from "./container/Aboutus/Aboutus";
import Contactus from "./container/Contact Us/Contactus";
import RightToRepresent from "./container/RightToRepresent/rtr";


function App() {

  return (
    <Provider store={store}>
      <div className="App">
        {/* <Header data="Job List" /> */}
        <Switch>
          <Route exact path="/hbits/contactus" component={Contactus} />
          <Route exact path="/hbits/rtr/:id" component={RightToRepresent} />
          <Route exact path="/hbits/aboutus" component={Aboutus} />
          <Route exact path="/hbits/applyjoblist" component={Applyjoblist} />
          <Route exact path="/hbits/Jobseeker" component={Job_seeker_list} />
          <Route exact path="/hbits/recruiter_signup" component={Recruiter_Signup} />
          <Route exact path="/hbits/signup" component={Signup} />
          <Route exact path="/hbits/login" component={Login} />
          <Route exact path="/hbits/applyform/:id" component={ApplyJobForm} />
          <Route exact path="/" render={() => <Redirect to="/hbits" />} />
          <Route exact path="/hbits" component={JobList} />
          <Route exact path="/hbits/:id" component={JobDetails} />
      
        </Switch>
        <Footer />
      </div>
    </Provider>
  );
}

export default App;

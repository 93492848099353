import React, { useEffect, useState } from "react";
import { Container, Col, Row, Button, Modal, Card } from "react-bootstrap";
import { get as _get } from "lodash";
import { connect, useDispatch } from "react-redux";
import { HiLocationMarker } from "react-icons/hi";
import { FcBookmark } from "react-icons/fc";
import { IoBriefcase } from "react-icons/io5";
// import { IoIosMail } from "react-icons/io";
import { FiClock } from "react-icons/fi";
import Header from '../../component/Header/Header';
// import ListGroup from 'react-bootstrap/ListGroup'
import Badge from 'react-bootstrap/Badge'
// import FigureImage from 'react-bootstrap/FigureImage'

// import logo from '../../images/logo.svg';

import { fetchEmailList } from "../../redux/JobList/jobListAction";
import ApplyJobForm from '../../component/ApplyJobForm/ApplyJobForm';

import Legend from '../../component/legend/legend';
// import ApplyJobForm from '../ApplyJobForm/ApplyJobForm';
// import "../../component/JobDetails/jobDeatils.scss";

const moment = require("moment");
moment.suppressDeprecationWarnings = true;


export const JobDetails = (props) => {
  // const [userlogin, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { EmailReceiverListData } = props;
  const id = props.match.params.id;

  useEffect(() => {
    dispatch(fetchEmailList());
    // setUser(JSON.parse(localStorage.getItem('profile')));
  }, [dispatch]);


  //***************15 Days Count Function****************  
  var date2 = moment().format('L');
  var date1 = '';
  const daycount = (sentdate) => {
    date1 = moment(sentdate).format("MM/DD/YYYY");
    return (new Date(date2) - new Date(date1)) / (1000 * 60 * 60 * 24);
  }


  return EmailReceiverListData.loading ? (
    <h2>Loading</h2>
  ) : EmailReceiverListData.error ? (
    <h2>{EmailReceiverListData.error}</h2>
  ) : (
    <>
      <Header data="Job Details" />
      <Container>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Job Apply Form</Modal.Title>
          </Modal.Header>
          <ApplyJobForm id={id} />
        </Modal>
        <Card className="my-3 shadow">
          <Card.Body>
            {EmailReceiverListData.jobListEntities.filter(data => data.HBITS_ID === id).map((emailReceiver, i) => (
              <div key={i}>
                <Row className="align-items-center">
                  <Col xs sm>
                    <h3>{_get(emailReceiver, 'positiontitle', "")}</h3>
                    <div className="mb-2">
                      {_get(emailReceiver, 'HBITS_ID', "")}
                    </div>
                    <h6 className="font-weight-bold">{_get(emailReceiver, 'agencyName', "")} </h6>
                    <div className="mb-2">
                      <Legend title={_get(emailReceiver, 'physicalWorkAddress', "")}>
                        <HiLocationMarker />
                      </Legend>
                    </div>
                    <div className="mb-2">
                      <Legend title={_get(emailReceiver, 'neworExistingPosition', "")}>
                        <FcBookmark />
                      </Legend>
                      <Legend title={_get(emailReceiver, 'positionSkill', "")}>
                        <IoBriefcase />
                      </Legend>
                      <Legend title={daycount(emailReceiver.sentDate) === 0 ? "Today " : <>{daycount(emailReceiver.sentDate)} Days ago </>} >
                        <FiClock />
                      </Legend>
                      <Legend title={<>Work Hours - {_get(emailReceiver, 'dailyWorkHrs', "")}</>}>
                        <FiClock />
                      </Legend>
                    </div>
                  </Col>
                  <Col xs="auto">
                  </Col>
                  <Col xs={12} sm="auto">
                    <Button variant="primary" disabled={daycount(emailReceiver.sentDate) <= 15 ? false : true} onClick={handleShow}>Apply Job</Button>{' '}
                    <Button variant="primary" href={`/hbits/rtr/${_get(emailReceiver, "HBITS_ID", "")}`}
                     disabled={daycount(_get(emailReceiver, 'sentDate', "")) <= 15 ? false : true}
                     > Right to Represent </Button>

                  </Col>
                </Row>
                <hr />
                <div className="mb-2">
                  <h5 className="font-weight-bold">Provide Short  Description</h5>
                  {_get(emailReceiver, 'provideShortDesc', "").split(/\. /).map((dd, j1) => dd.trim() &&
                    <p key={j1}>
                      {dd}.
                    </p>
                  )
                  }
                </div>
                <hr />
                <div className="mb-2">
                  <h5 className="font-weight-bold">Program Task</h5>
                  <ul>
                    {emailReceiver.programTask !== undefined &&
                      emailReceiver.programTask !== null &&
                      Object.values(emailReceiver.programTask).map((PT, jj) =>
                        <li key={jj} as="li">
                          {PT.split(/\n/).map((dd, j1) =>
                            <div key={j1}>
                              {
                                dd.length > 3 &&
                                <React.Fragment>{dd}</React.Fragment>
                              }
                            </div>
                          )}
                        </li>
                      )}
                  </ul>
                </div>
                <hr />
                <div className="mb-3">
                  <h5 className="font-weight-bold">Request Qualification Number</h5>
                  <ul>
                    {
                      _get(emailReceiver, 'requestQualificationNumber') !== undefined &&
                      _get(emailReceiver, 'requestQualificationNumber') !== null &&
                      Object.values(_get(emailReceiver, 'requestQualificationNumber')).map((data, j) =>
                      data.trim() &&
                      <li>
                          {data}
                        </li>
                      )}
                  </ul>
                </div>
                <hr />
                <div className="font-weight-bold">Software Used By Agency</div>
                {_get(emailReceiver, 'softwareUsedByAgency', "").split(/,/).map((dd, j1) =>
                  <Badge variant="primary" key={j1} className="mr-2">
                    {dd}
                  </Badge>
                )}
              </div>
            ))}
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}


const mapStateToProps = (state) => {
  return {
    EmailReceiverListData: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEmailList: () => dispatch(fetchEmailList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(JobDetails));




import React, { Fragment } from 'react'
import Container from 'react-bootstrap/Container'
// import Tabs from 'react-bootstrap/Tabs'
// import Tab from 'react-bootstrap/Tab'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Header from '../../component/Header/Header'


export default function Contactus(props) {
    return (
        <Fragment>
					<Header data="Contact Us"/>
					<Container>
						<div className="border p-3 my-3 shadow">
							<Row>
								<Col xs={12} sm={8}>
									{/* <Tabs defaultActiveKey="usa" id="contact"> */}
										{/* <Tab eventKey="usa" title="New Jersey, USA" className="border border-top-0">
											<div className="p-3">
												<iframe width="100%" height="400" src="http://maps.google.com/maps?q=Greycell+Labs+Inc.++190+Lincoln+Hwy++Edison+NJ+08820&amp;output=embed" frameBorder="0"></iframe>
											</div>
										</Tab> */}
										<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3031.014937724709!2d-74.33758374910285!3d40.56334737924692!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3b6609f9a30ad%3A0xcfa37fb310ca6ad3!2sGreycell%20Labs%2C%20Inc.!5e0!3m2!1sen!2sin!4v1620371444064!5m2!1sen!2sin" width="100%" title='Greycell' height="450" frameBorder="0" allowfullscreen="" loading="lazy"></iframe>
										{/* <Tab eventKey="india" title="Gujarat, India" className="border border-top-0"> */}
											{/* <div className="p-3"> */}
											{/* <iframe width="100%" height="400" src="http://maps.google.com/maps?q=Titanium+City+Centre%2C+Ahmedabad%2C+Gujarat&amp;output=embed" frameBorder="0"></iframe> */}
											{/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.3348727959365!2d72.5212815504462!3d23.011473584884886!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e84d582797733%3A0xbcb45cb8bc18718f!2sGreycell%20Labs%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1619181567489!5m2!1sen!2sin" width="100%" height="450" frameBorder="0" allowfullscreen="" loading="lazy"></iframe> */}
											{/* </div> */}
										{/* </Tab> */}
									{/* </Tabs> */}
								</Col>
								<Col xs={12} sm={4} className="pt-3 pt-sm-0">
									<h5>GREYCELL - USA OFFICE</h5>
									<h6>Greycell Labs Inc</h6>
									<div>190 Lincoln Hwy</div>
									<div>EDISON, NJ - 08820</div>
									<div>USA</div>
									<div>P: 732-444-0122/23/24</div>
									<div>F: 732-444-0121</div>
									{/* <hr /> */}
									{/* <h5>GREYCELL - INDIA OFFICE</h5>
									<h6>Greycell Labs</h6>
									<div>E-109, Titanium City Centre,</div>
									<div>100 ft Anandnagar Road,</div>
									<div>Next to Sachin Towers,</div>
									<div>Satellite, AHMEDABAD</div>
									<div>Gujarat - 380015</div> */}
								</Col>
							</Row>
						</div>
					</Container>
        </Fragment>
    )
}

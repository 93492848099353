import axios from "axios";
import { getUrl } from "../../Utils";
import { map as _map } from "lodash";
import {
  FETCH_EMAIL_LIST_REQUEST,
  FETCH_EMAIL_LIST_SUCCESS,
  FETCH_EMAIL_LIST_FAILURE,
} from "./jobListType";
const moment = require("moment");

const dayCount = (Data) => {
  var date2 = moment().format('L');
  let checkId = []
  _map(Data, data => {
    let date1 = moment(data.sentDate).format("MM/DD/YYYY");
    let count = (new Date(date2) - new Date(date1)) / (1000 * 60 * 60 * 24);
    if (count <= 30) {
      checkId.push(data);
    }
  })
  return checkId
}

export const fetchEmailList = () => {
  return (dispatch) => {
    dispatch(fetchEmailListRequest());
    // axios.get("http://localhost:3001/jobList")
    axios.get(`${getUrl()}/jobList`)
      .then((response) => {
        const jobListEntities = response.data.reverse();
        const Data = dayCount(jobListEntities);
        dispatch(fetchEmailListSuccess(Data));
      })
      .catch((error) => {
        dispatch(fetchEmailListFailure(error.message));
      });
  };
};


export const fetchEmailListRequest = () => {
  return {
    type: FETCH_EMAIL_LIST_REQUEST,
  };
};

export const fetchEmailListSuccess = (jobListEntities) => {
  return {
    type: FETCH_EMAIL_LIST_SUCCESS,
    payload: jobListEntities,
  };
};

export const fetchEmailListFailure = (error) => {
  return {
    type: FETCH_EMAIL_LIST_FAILURE,
    payload: error,
  };
};

import {
    RTR_SUCCESS,
    RTR_FAILURE,
  } from "./Type";
  
  const initialState = {
    RtrEntities: [],
    error: "",
  };
  
  const RtrReducer = (state = initialState, action) => {
    switch (action.type) {

      case RTR_SUCCESS:
        return {
          ...state,
          loading: false,
          RtrEntities: action.payload,
          error: "",
        };
      case RTR_FAILURE:
        return {
          loading: false,
          RtrEntities: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default RtrReducer;
  